<template>
  <div class="national-day" @click="handleEnter">
    <a :href="buttonLink">
      <div class="national-day-button">
        <button class="enter-btn">快速进入</button>
      </div>
      <div class="national-day-side rule">
        <div class="img-wrap">
          <img src="https://static1.kaixinyf.cn/img/lza6327388f98ec6321290048.png" alt="rule" />
        </div>
      </div>
      <div class="national-day-side share">
        <div class="img-wrap">
          <img src="https://static1.kaixinyf.cn/img/lza632738ad66b46741827244.png" alt="share" />
        </div>
      </div>
      <ul class="province-list" v-if="Provinces.length !== 0">
        <li
          class="province-item"
          :style="{ background: `url(${province.cover}) no-repeat center center` }"
          v-for="province in Provinces"
        >
          <div class="province-item-level" v-if="rankLevelMap.get(province.rank)">
            <img :src="rankLevelMap.get(province.rank)" alt="" />
          </div>
          <div class="province-item-mask"></div>
          <div class="province-item-name">{{ province.name }}</div>
          <div class="province-item-content">
            <div class="king" v-if="Object.keys(province.top_user).length">
              <img :src="province.top_user.avatar_small" alt="avatar_small" />
              <p>助力王：{{ province.top_user.nickname }}</p>
            </div>
            <div class="score" v-if="Object.keys(province.top_user).length">
              <img src="https://static1.kaixinyf.cn/img/lza6327360d88f96687964801.png" alt="avatar_small" />
              <!-- <p>助力值：{{province.top_user.}}</p> -->
              <p>助力值：{{ province.score }}</p>
            </div>
          </div>
        </li>
      </ul>
    </a>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import API from '@/api';
  import { mapGetters } from 'vuex';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  import Download from '@/utils/download';

  export default {
    name: 'NationalDay',
    components: { IntroModal },
    async asyncData({ store, $http, params }) {
      const { device_id, app_name, client_v } = params;
      const { provinces } = await $http.post(API.GET_NATIONAL_DAY_PAGE_INFO, {
        device_id,
        app_name,
        client_v,
      });

      store.commit('activity/national/SET_NATIONAL_PAGE_INFO', provinces);
    },
    data() {
      return {
        rankLevelMap: new Map([
          [1, 'https://static1.kaixinyf.cn/img/lza63b3e58f6e36f590310908.png'],
          [2, 'https://static1.kaixinyf.cn/img/lza63b3e59f8a514638240809.png'],
          [3, 'https://static1.kaixinyf.cn/img/lza63b3e5b159a04686600372.png'],
        ]),
        showIntro: false,
        buttonLink: 'javascript:void(0);',
      };
    },
    computed: {
      ...mapGetters('activity/national', ['Provinces']),
    },
    mounted() {
      if (this.$platform.Android && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `http://h5.rd.kaixinyf.cn/activity/spring-festival`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/activity/spring-festival`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/activity/spring-festival`;
        }

        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      handleEnter() {
        console.log(`handleEnter`);
        try {
          let jump = () => {
            let url;

            if (this.$platform.Android && this.$platform.WeiXin) {
              // url = Download.downLoad('yyb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if (this.$platform.Android && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              url = Download.downLoad('yyb');
            }
            window.location.href = url;
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>

<style scoped lang="less">
  .national-day {
    position: relative;
    min-height: 100vh;
    background: #f7f8fa;

    &-button {
      display: flex;
      height: 1.6rem;
      justify-content: center;
      align-items: center;
      .enter-btn {
        border: none;
        font-size: 0.36rem;
        width: 5.1rem;
        height: 0.86rem;
        color: #ffffff;
        border-radius: 0.43rem;
        background: linear-gradient(360deg, #FA3E00 0%, #FD6500 100%);
      }
    }
    &-side {
      position: fixed;
      top: 0.36rem;
      right: 0;
      width: 0.98rem;
      height: 0.86rem;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 1rem 0px 0px 1rem;
      z-index: 99;
      .img-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.06rem;
        margin-left: 0.03rem;
        width: 0.74rem;
        height: 0.74rem;
        background: #ffffff;
        border-radius: 50%;
        img {
          width: 0.58rem;
          height: 0.58rem;
        }
      }
    }
    .share {
      top: 1.42rem;
    }
  }
  .province-list {
    padding: 0 0.3rem 0.2rem;
    .province-item {
      position: relative;
      width: 6.9rem;
      height: 3.88rem;
      border-radius: 0.24rem;
      background-size: cover !important;
      margin-bottom: 0.2rem;
      &-mask {
        position: absolute;
        width: 100%;
        height: 1.94rem;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
        border-radius: 0 0 0.24rem 0.24rem;
      }
      &-level {
        position: absolute;
        top: -0.22rem;
        left: 0.34rem;
        img {
          width: 0.98rem;
          height: 1.08rem;
        }
      }
      &-name {
        position: absolute;
        bottom: 1rem;
        left: 0.46rem;
        font-size: 0.5rem;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 0.58rem;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      }
      &-content {
        position: absolute;
        left: 0.26rem;
        bottom: 0.4rem;
        display: flex;
        align-items: center;
        width: 5rem;
        height: 0.48rem;
        background: rgba(131, 131, 131, 0.5);
        border-radius: 0.28rem;
        .king {
          margin-left: 0.2rem;
          display: flex;
          align-items: center;
          img {
            width: 0.26rem;
            height: 0.26rem;
            border: 1px solid #ffffff;
            border-radius: 50%;
          }
          p {
            margin-left: 3px;
            width: 2.16rem;
            font-size: 0.24rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #ffffff;
          }
        }
        .score {
          margin-left: 0.26rem;
          display: flex;
          align-items: center;
          img {
            width: 0.26rem;
            height: 0.26rem;
          }
          p {
            margin-left: 3px;
            width: 2.16rem;
            font-size: 0.24rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
